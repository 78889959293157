<template>
  <div :style="{ height: '100%' }" v-if="atStoreMap">
    <!-- header -->
    <header-cart>
      <template slot="header-left">
        <a class="btn-link" href="javascript:void(0);" @click="switchDynamicComponent('store-select')">
          <i class="icon icon-arrow-left"></i>
        </a>
      </template>
      <template slot="header-middle">門市資訊</template>
      <template slot="header-right">
        <a class="nav-link" href="javascript:void(0);" @click="confirm"> 確認 </a>
      </template>
    </header-cart>
    <!-- content -->
    <main class="offset bottom gray">
      <!-- NAV -->
      <div class="notice-bar absolute top">{{ typeName }} {{ storeCity }} {{ storeTown }}</div>
      <!-- 地圖 -->
      <div class="google-map" id="map" :style="height()"></div>
      <div class="store-check-bar fixed bottom">
        <div class="store-info">
          <store-hours :info="storeInfoOnMap" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { calculateWeekListMatchingDay } from '@/helpers/date';
import { commitMutation, commitMutations, getGetter, renderGetter, renderState, getState } from '@/helpers/vuex';
import { loadGoogleMapsAPI } from '@/helpers/getNearbyStore';
import headerCart from '@/components/mobile/Header/header-cart.vue';
import storeHours from '@/components/mobile/step1/store-hours.vue';

const components = {
  headerCart,
  storeHours,
};

const data = () => ({
  map: null,
  storePosition: {
    lat: renderGetter('CityTownStores', 'lat'),
    lng: renderGetter('CityTownStores', 'lng'),
  },
  show: true,
});

const methods = {
  initMap() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: this.storePosition,
      zoom: 18,
    });
  },
  setMarker() {
    const marker = new google.maps.Marker({
      position: this.storePosition,
      map: this.map,
    });
    const InfoWindow = new google.maps.InfoWindow({
      content: `
          <div id="content">
            <h3 class="caption">${this.typeName}-${this.storeName}</h3>
            <ul class="content">
              <li>門市地址： ${this.storeFullAddress}</li>
              <li>門市電話： ${this.storeTel}</li>
              <li>營業時間： ${this.storeOpenOrClosedText}
              </span> ⋅ ${this.weekList[this.calculateWeekListMatchingDay].day}${this.todayTime}
              <li>服務項目： ${this.serviceItems}</li>
              <li>停車資訊：<a class="underline" target="_blank" href="https://www.google.com.tw/maps/search/${
                this.storeFullAddress
              }停車">查看資訊</a></li>
            </ul>
            <div class="btn-group">
              <a class="btn btn-query btn-sm" target="_blank" href="https://www.google.com.tw/maps/search/${
                this.storeFullAddress
              }" style="color: #fff;margin-top: 5px;">如何前往</a>
            </div>
          </div>
        `,
      maxWidth: 350,
    });
    InfoWindow.open(this.map, marker);
    marker.addListener('click', function () {
      InfoWindow.open(this.map, marker);
    });
  },
  switchDynamicComponent(component) {
    commitMutation('DynamicComponents', 'deliveryMethod', component);
  },
  saveSelectedStoreForOrderUse() {
    const { storeName, storeId, typeName, tel, fullAddress, zipcode } = this.storeInfoOnMap[0];
    commitMutations('RecipientInfo', {
      senaoStore: storeName,
      senaoStoreId: storeId,
      typeName,
      tel,
      senaoStoreAddress: fullAddress,
      senaoStoreZipcode: zipcode,
    });
  },
  mutateStore() {
    commitMutation('RecipientInfo', 'selectStore', this.storeInfoOnMap);
  },
  confirm() {
    this.saveSelectedStoreForOrderUse();
    this.mutateStore();
    this.switchDynamicComponent('step1-store');
  },
  height() {
    const height = window.innerHeight;
    return `height:${height}px`;
  },
};

export default {
  name: 'store-googleMap',
  components,
  data,
  computed: {
    /**
     * 取 CityTownStores 的值是因為該值只用在 store-googleMap.vue 使用
     * */
    typeName: getGetter('CityTownStores', 'typeName'),
    storeName: getGetter('CityTownStores', 'store'),
    storeCity: getGetter('CityTownStores', 'county'),
    storeTown: getGetter('CityTownStores', 'town'),
    serviceItems: getGetter('CityTownStores', 'serviceItems'),
    storeFullAddress: getGetter('CityTownStores', 'fullAddress'),
    storeTel: getGetter('CityTownStores', 'tel'),
    todayTime() {
      return this.weekList[this.calculateWeekListMatchingDay].time;
    },
    atStoreMap() {
      const dynamicComponent = renderState('DynamicComponents', 'deliveryMethod');
      return dynamicComponent === 'store-google-map';
    },
    weekList: getGetter('CityTownStores', 'weekList'),
    storeOpenOrClosedText() {
      return this.todayTime === '休息' ? '休息中' : '營業中';
    },
    storeOpenOrClosedStyle() {
      return this.todayTime === '休息' ? 'st_red' : 'st_green';
    },
    calculateWeekListMatchingDay,
    storeInfoOnMap: getState('RecipientInfo', 'selectStoreForMap'),
  },
  methods,
  mounted() {
    loadGoogleMapsAPI.then(() => {
      this.initMap();
      this.setMarker();
    });
  },
};
</script>
<style scoped>
.google-map {
  width: 100%;
}
</style>
