<template>
  <div v-if="isOrderAddress">
    <p class="has-type-input">{{ hasCityName }}{{ hasTownName }}{{ maskedAddress }}</p>
  </div>
  <div v-else class="select-group half">
    <city-town-select
      v-model="cityIndex"
      :source="cities"
      v-validate="isNotPleaseSelect"
      @zip-change="onCityChange"
      :name="cityName"
      :key="cityName"
      :disabled="isRtype"></city-town-select>
    <city-town-select
      v-model="townIndex"
      :source="towns"
      v-validate="isNotPleaseSelect"
      :name="townName"
      :key="townName"
      :disabled="isRtype"></city-town-select>
    <input
      v-if="isNotMasked"
      v-model.trim="address"
      v-validate="addressRules"
      :name="addressName"
      :disabled="isRtype"
      type="text"
      maxlength="50"
      placeholder="請填寫完整地址"
      class="input-lg" />
    <input v-else v-model.trim="maskedAddress" type="text" @focus="isNotMasked = true" />
    <p v-if="errors.has(cityName)" class="error">* {{ errors.first(cityName) }}</p>
    <p v-if="errors.has(townName)" class="error">* {{ errors.first(townName) }}</p>
    <p v-if="errors.has(addressName)" class="error">* {{ errors.first(addressName) }}</p>
  </div>
</template>

<script>
import { propEq } from 'ramda';
import { commitMutation, commitMutations, renderState, getState } from '@/helpers/vuex';
import { findIndexDefault } from '@/helpers/array';
import { regexpAddress } from '@/helpers/city-town';
import { useMask } from '@/composables/mask';
import { defaultCity, defaultTown } from '@/components/desktop/step1/common/city-town/city-town.default';
import CityTownSelect from '@/components/desktop/step1/common/city-town/city-town-select.vue';

const index = {
  addressCityId: 0,
  addressCity: 1,
  addressTownId: 2,
  addressTown: 3,
  addressZip: 4,
  address: 5,
};

/** 所有 API 資料 */
const allCityTownTaiwan = getState('CityTownTaiwan', 'allCityTownTaiwan');

/** 綁定 city 的 index */
const cityIndex = {
  get() {
    const val = renderState(this.module, 'addressCityId');
    return findIndexDefault(propEq('id', val), 0, this.cities);
  },
  set(value) {
    this.cityIndexData = value;
    commitMutations(this.module, {
      [this.states[index.addressCityId]]: this.cities[value].id,
      [this.states[index.addressCity]]: this.cities[value].name,
    });
  },
};

/** 綁定 town 的 index */
const townIndex = {
  get() {
    const val = renderState(this.module, 'addressTownId');
    return findIndexDefault(propEq('id', val), 0, this.towns);
  },
  set(value) {
    this.townIndexData = value;
    commitMutations(this.module, {
      [this.states[index.addressTownId]]: this.towns[value].id,
      [this.states[index.addressTown]]: this.towns[value].name,
    });
  },
};

/** 產生是否外島的 filter function */
const isIslandInCities = (island) => {
  if (island) return () => true;
  return (x) => !x.districts.every((y) => y.isIsland === !island);
};

/** 顯示第一層 select 所需的 array */
const cities = function () {
  return [defaultCity /** 請選擇 */, ...this.allCityTownTaiwan.cities.filter(isIslandInCities(this.island))];
};

/** 產生是否外島的 filter function */
const isIslandInTowns = (island) => {
  if (island) return () => true;
  return (x) => x.isIsland === island;
};

/** 顯示第二層 select 所需的 array */
const towns = function () {
  return [defaultTown /** 請選擇 */, ...this.cities[this.cityIndex].districts.filter(isIslandInTowns(this.island))];
};

/** 算出第三層所顯示的'郵遞區號' */
const zipValue = function () {
  return this.towns[this.townIndex].zip;
};

/** 顯示第三層所顯示的'郵遞區號' */
const zip = {
  get() {
    return this.zipValue;
  },
  set(value) {
    commitMutation(this.module, this.states[index.addressZip], value);
  },
};

/**
 * 收件地址驗證規則
 *  必填
 *  最少 6 個字，最多 60 個字
 *  不可包含 `郵遞區號` 或 `郵遞 .... 區號`
 * */
const addressRules = () => 'required|address-length|postal-box';

/** [收件地址] */
const address = {
  get() {
    return renderState(this.module, 'address');
  },
  set(value) {
    commitMutation(this.module, this.states[index.address], regexpAddress(value));
  },
};

/** 鄉鎮市不能是 `請選擇` */
const isNotPleaseSelect = () => ({ is_not: 0 });

/** [收件地址之市 ID] */
const addressCityId = function () {
  return renderState(this.module, 'addressCityId');
};

/** [收件地址之鄉鎮區 ID] */
const addressTownId = function () {
  return renderState(this.module, 'addressTownId');
};

/** 當 [addressCityId] 改變時，找出城市的 select index */
const watchAddressCityId = function (value) {
  this.cityIndexData = findIndexDefault(propEq('id', value), 0, this.cities);
};

/** 當 [addressTownId] 改變時，找出鄉鎮市的 select index */
const watchAddressTownId = function (value) {
  this.townIndexData = findIndexDefault(propEq('id', value), 0, this.towns);
  /** 更新後重新驗證表單 */
  this.$validator.validate();
};

/** 當 [zip] 改變時，直接 mutate state */
const watchZip = function (value) {
  commitMutation(this.module, this.states[index.addressZip], value);
};

/** 當城市改變時，馬上將鄉鎮區 reset 從頭開始，避免舊資料殘留 */
const onCityChange = function () {
  this.townIndex = 0;
};

/** show 在會員中心裡留的縣市資料 */
const hasCityName = () => renderState('OrderInfo', 'addressCity');

/** show 在會員中心裡留的鄉鎮區資料 */
const hasTownName = () => renderState('OrderInfo', 'addressTown');

/** show 在會員中心裡留的地址資料 */
const hasAddress = () => renderState('OrderInfo', 'address');

export default {
  name: 'city-town-taiwan',
  inject: ['$validator'],
  components: {
    CityTownSelect,
  },
  props: {
    /** 與 Vuex 綁定的 module 名稱，有 "RecipientInfo" 與 "ATMInfo" */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'];
      },
    },
    /** 是否包含外島 */
    island: {
      type: Boolean,
      default: true,
    },
    addressName: {
      type: String,
      default: 'addressName',
    },
    cityName: {
      type: String,
      default: 'cityName',
    },
    townName: {
      type: String,
      default: 'townName',
    },
    message: {
      type: String,
      default: '',
    },
    isRtype: {
      type: Boolean,
      default: false,
    },
    // 預設為 false，只在訂購區有作用切換有或無資料時的樣式
    isOrderAddress: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    /** select 改變時動態改變的 index，為 v-model */
    cityIndexData: 0,
    townIndexData: 0,
  }),
  computed: {
    /** 所有 API 資料 */
    allCityTownTaiwan,
    cityIndex,
    townIndex,
    cities,
    towns,
    zipValue,
    zip,
    addressRules,
    address,
    isNotPleaseSelect,
    addressCityId,
    addressTownId,
    hasCityName,
    hasTownName,
    hasAddress,
  },
  watch: {
    addressCityId: watchAddressCityId,
    addressTownId: watchAddressTownId,
    zip: watchZip,
  },
  mounted() {
    this.$validator.validate();
  },
  methods: {
    onCityChange,
  },
  created() {
    this.setIsNotMaskedByValue(this.address);
  },
  setup(props) {
    const { maskedAddress, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedAddress, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>

<style scoped>
input[type='text'][readonly]:disabled {
  background-color: #e9eaea;
}
</style>
