<template>
  <div class="fieldset" :class="{ 'lock-form-box': isHasOrderEmail }">
    <label>
      <b class="notice">*</b>
      {{ prefix }}
    </label>
    <p v-if="isHasOrderEmail" class="has-type-input">
      {{ maskedEmail }}
    </p>
    <input
      v-else
      v-model="email"
      v-validate="'required|custom-email'"
      :name="errMsg"
      type="text"
      placeholder="請填寫E-Mail"
      maxlength="40"
      autocomplete="off" />
    <p class="error">{{ errors.first(errMsg) }}</p>
  </div>
</template>

<script>
import { createComputed, renderState } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

const isHasOrderEmail = () => renderState('OrderInfo', 'isHasOrderMail');

export default {
  name: 'order-mail',
  inject: ['$validator'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
    errMsg: {
      type: String,
      default: '',
    },
    module: {
      type: String,
      default: '',
    },
  },
  computed: {
    isHasOrderEmail,
    email: createComputed('OrderInfo', 'email'),
  },
  setup(props) {
    const { maskedEmail } = useMask(props);
    return { maskedEmail };
  },
};
</script>
