import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import zhTW from 'vee-validate/dist/locale/zh_TW';
import { required, length, isNot } from '@/validators/config/dictionary';
import {
  recipientName,
  payerName,
  mobilePhone,
  cellPhone,
  city,
  town,
  address,
  store,
} from '@/validators/config/attribute';
import config from '@/validators/config/config';
import addressLengthRule from '@/validators/rules/address-length';
import customEmailRule from '@/validators/rules/custom-email';
import localPhoneAreaRule from '@/validators/rules/local-phone-area';
import localPhoneExtRule from '@/validators/rules/local-phone-ext';
import localPhoneRule from '@/validators/rules/local-phone';
import mobilePhoneRule from '@/validators/rules/mobile-phone';
import postalBoxRule from '@/validators/rules/postal-box';
import selectRequiredRule from '@/validators/rules/select-required';
import chineseOnlyRule from '@/validators/rules/chinese-only';

/** 自訂欄位、訊息字典檔 */
const dictionary = {
  zh_TW: {
    messages: {
      is_not: isNot,
      length,
      required,
    },
    attributes: {
      /** 收件人姓名 */
      recipientName,
      name: recipientName,
      /** 付款人姓名 */
      payerName,
      mobilePhone,
      cellPhone,
      city,
      town,
      addressCity: city,
      addressTown: town,
      address,
      store,
    },
  },
};

/** 設定語系 */
const setLocale = () => Validator.localize('zh_TW', zhTW);

/** 設定字典 */
const setDictionary = () => Validator.localize(dictionary);

/** 設定自訂驗證規則 */
const setCustomRules = () => {
  Validator.extend('mobile-phone', mobilePhoneRule);
  Validator.extend('local-phone-area', localPhoneAreaRule);
  Validator.extend('local-phone', localPhoneRule);
  Validator.extend('local-phone-ext', localPhoneExtRule);
  Validator.extend('address-length', addressLengthRule);
  Validator.extend('select-required', selectRequiredRule);
  Validator.extend('postal-box', postalBoxRule);
  Validator.extend('chinese-only', chineseOnlyRule);
  Validator.extend('custom-email', customEmailRule);
};

export default () => {
  Vue.use(VeeValidate, config);
  setLocale();
  setDictionary();
  setCustomRules();
};
