<template>
  <div class="container" v-if="atStoreSelect">
    <!-- header -->
    <header-cart>
      <template slot="header-left">
        <a class="btn-link" href="javascript:void(0);" @click="switchDynamicComponent('step1-store')">
          <i class="icon icon-arrow-left"></i>
        </a>
      </template>
      <template slot="header-middle">選擇取貨門市</template>
      <template slot="header-right">
        <div></div>
      </template>
    </header-cart>
    <!-- content -->
    <main class="offset bottom gray">
      <!-- 服務類型 -->
      <type-selector :source="typeSource" :picked="serviceCenter" disabled></type-selector>
      <!-- 地區、門市下拉選單 -->
      <store-selector :module="module">
        <template slot="label">查找地區</template>
        <template slot="middle-label"><label>選擇門市</label></template>
      </store-selector>
    </main>
    <!-- 按鈕 -->
    <div class="btn-group spacing top md">
      <a class="btn btn-primary-outline btn-round" href="javascript:void(0);" @click="showMap"> 查看地圖 </a>
      <a class="btn btn-primary btn-round" href="javascript:void(0);" @click="confirm"> 確認選取 </a>
    </div>
  </div>
</template>

<script>
import { commitMutation, commitMutations, getGetter, renderState } from '@/helpers/vuex';
import { calculateWeekListMatchingDay } from '@/helpers/date';
import { storeType } from '@/components/mobile/step1/constant';
import headerCart from '@/components/mobile/Header/header-cart.vue';
import storeSelector from '@/components/mobile/step1/form-item/store-location-selector.vue';
import typeSelector from '@/components/mobile/step1/form-item/store-type-selector.vue';

const components = {
  headerCart,
  storeType,
  typeSelector,
  storeSelector,
};

const props = {
  module: String,
};

/** 顯示門市資訊的邏輯情境紀錄:
 * 1.無選取過門市且無 API Data，進 store-select 頁挑選門市 -> 進 store-google-map 頁 -> 無存按返回 -> 門市資訊應為空
 * 2.無選取過門市但有 API Data 時，mounted 打完門市 API (未進 store-select 頁) -> 應顯示門市資訊
 * 3.有選取過門市且有進過 store-google-map 頁 -> 再進 store-select 頁 or 進 store-google-map 頁 -> 無存按返回 -> 應顯示上一次選取的門市名稱
 * 4.有選取過門市無進過 store-google-map 頁 -> 無存按返回 -> 應顯示上一次選取的門市名稱
 */
const methods = {
  saveTempStoreForUI() {
    this.selectStore.push({
      storeName: this.storeName,
      storeId: this.storeId,
      typeName: this.typeName,
      tel: this.tel,
      fullAddress: this.fullAddress,
      todayTime: this.todayTime,
      weekList: this.weekList,
      zipcode: this.zipcode,
    });
  },
  mutateStore(module) {
    commitMutation('RecipientInfo', module, this.selectStore);
  },
  switchDynamicComponent(component) {
    commitMutation('DynamicComponents', 'deliveryMethod', component);
  },
  resetTempStore() {
    this.selectStore = [];
  },
  saveSelectedStoreForOrderUse() {
    const { storeName, storeId, typeName, tel, fullAddress, zipcode } = this.selectStore[0];
    commitMutations('RecipientInfo', {
      senaoStore: storeName,
      senaoStoreId: storeId,
      typeName,
      tel,
      senaoStoreZipcode: zipcode,
      senaoStoreAddress: fullAddress,
    });
  },
  confirm() {
    this.$validator.validateAll().then((isValid) => {
      if (!isValid || this.storeId === undefined) {
        return;
      }
      this.resetTempStore();
      this.saveTempStoreForUI();
      this.saveSelectedStoreForOrderUse();
      this.mutateStore('selectStore');
      this.switchDynamicComponent('step1-store');
    });
  },
  showMap() {
    this.$validator.validateAll().then((isValid) => {
      if (!isValid || this.selectStoreId === undefined) {
        return;
      }
      this.saveTempStoreForUI();
      this.mutateStore('selectStoreForMap');
      this.switchDynamicComponent('store-google-map');
    });
  },
};

export default {
  name: 'store-select',
  inject: ['$validator'],
  components,
  props,
  data: () => ({
    // for 「服務類型」
    typeSource: storeType,
    // 服務類型預設:特約服務中心
    serviceCenter: 2,
    selectStore: [],
  }),
  computed: {
    selectStoreId: getGetter('CityTownStores', 'storeId'),
    storeName: getGetter('CityTownStores', 'store'),
    storeId: getGetter('CityTownStores', 'storeId'),
    typeName: getGetter('CityTownStores', 'typeName'),
    tel: getGetter('CityTownStores', 'tel'),
    fullAddress: getGetter('CityTownStores', 'fullAddress'),
    todayTime() {
      return this.weekList[this.calculateWeekListMatchingDay].time;
    },
    weekList: getGetter('CityTownStores', 'weekList'),
    serviceItems: getGetter('CityTownStores', 'serviceItems'),
    zipcode: getGetter('CityTownStores', 'zipcode'),
    calculateWeekListMatchingDay,
    atStoreSelect() {
      const dynamicComponent = renderState('DynamicComponents', 'deliveryMethod');
      return dynamicComponent === 'store-select';
    },
  },
  methods,
};
</script>

<style scoped>
main.offset.bottom {
  padding-bottom: 0;
}
</style>
