<template>
  <div class="fieldset">
    <label>
      <b class="notice">*</b>
      {{ prefix }}
      <a v-if="isLoginUser" class="underline pull-right" href="javascript:void(0);" @click="showAddressee()">
        使用收件人記事本
      </a>
      <a
        href="javascript:void(0);"
        class="underline pull-right"
        tabindex="-1"
        @blur="handleBlur"
        @click="copyBuyerInfo('RecipientInfo', orderInfoZip)">
        同訂購人資訊
      </a>
    </label>
    <input
      v-if="isNotMasked"
      v-model="name"
      v-validate="'required'"
      :name="errMsg"
      autocomplete="off"
      maxlength="7"
      type="text" />
    <input v-else v-model="maskedName" type="text" @focus="isNotMasked = true" />
    <p class="error" v-if="errors.has(errMsg)">
      <b class="notice">*</b>
      {{ errors.first(errMsg) }}
    </p>
  </div>
</template>

<script>
import { compose, prop, gt, __ } from 'ramda';
import { commitMutation, getState, getAction, createComputed } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import { useMask } from '@/composables/mask';

/**  vuex 裡的 addressees 的長度大於 0 則返回 true */
const hasAddresseesList = compose(gt(__, 0), prop('length'), getState('AddressModalInfo', 'addressees'));

const showAddressee = function () {
  /** 如果有收件人資料或有打過[收件人記事本] API */
  if (hasAddresseesList() || this.hasLoadAddressInfo) {
    // 發出跳頁事件
    commitMutation('DynamicComponents', 'deliveryMethod', 'address-modal');
  }
  if (!this.hasLoadAddressInfo) {
    /** load [收件人記事本] API */
    getAction('AddressModalInfo', 'getAddressees')().then(() => {
      /** 寫入已 load 過[收件人記事本] API 的紀錄 */
      commitMutation('AddressModalInfo', 'hasLoadAddressInfo', true);
      // 發出跳頁事件
      commitMutation('DynamicComponents', 'deliveryMethod', 'address-modal');
    });
  }
};

export default {
  name: 'recipient-name',
  inject: ['$validator'],
  props: {
    /** 前面所顯示的字串 */
    prefix: {
      type: String,
      default: '',
    },
    /** 後面所顯示的字串 */
    postfix: {
      type: String,
      default: '',
    },
    /** 錯誤訊息 */
    errMsg: {
      type: String,
      default: '',
    },
    /** 如從「收件人資訊頁」來的則顯示 -收件人記事本- 連結 ; 如從「取貨門市」則否 */
    atRecipientInfo: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: '',
    },
  },
  computed: {
    name: createComputed('RecipientInfo', 'name'),
    /** 從 API 下載的 [收件人記事本] */
    addressees: getState('AddressModalInfo', 'addressees'),
    /** 是否有打過 [收件人記事本] API */
    hasLoadAddressInfo: getState('AddressModalInfo', 'hasLoadAddressInfo'),
    // 目前訂購人的郵遞區號，供匯入收件人時檢查是否為外島用
    orderInfoZip: getState('OrderInfo', 'addressZip'),
    isLoginUser,
  },
  methods: {
    showAddressee,
  },
  created() {
    this.setIsNotMaskedByValue(this.name);
  },
  setup(props) {
    const { maskedName, isNotMasked, handleBlur, copyBuyerInfo, setIsNotMaskedByValue } = useMask(props);
    return { maskedName, isNotMasked, handleBlur, copyBuyerInfo, setIsNotMaskedByValue };
  },
};
</script>
