<template>
  <div class="fieldset">
    <label>服務類型</label>
    <select name="storeType" :disabled="disabled">
      <option v-for="(item, index) in source" :key="index" :value="item.type">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
const props = {
  source: Array,
  picked: Number,
  disabled: Boolean,
};

const data = function () {
  return {
    input: this.picked,
  };
};

export default {
  name: 'store-type-selector',
  props,
  data,
};
</script>
