<template>
  <div class="container">
    <!-- header -->
    <header-cart>
      <template slot="header-left">
        <a class="btn-link" href="javascript:void(0);" @click="goBack()">
          <i class="icon icon-arrow-left"></i>
        </a>
      </template>
      <template slot="header-middle">填寫配送資料</template>
      <template slot="header-right">
        <div></div>
      </template>
    </header-cart>
    <!-- if info empty -->
    <main v-if="!hasInfo">
      <div>
        <h4 class="content">尚未設定收件人資訊</h4>
        <div class="btn-group">
          <a href="javascript:void(0);" class="btn btn-primary btn-round" @click="goBack()"> 關閉 </a>
        </div>
      </div>
    </main>
    <!-- has info data -->
    <main v-if="hasInfo">
      <div class="check-table">
        <div class="check-cell" v-for="(item, index) in addressees" :key="index">
          <div class="radio-block">
            <input type="radio" :value="item.id" name="receiptItem" v-model="picked" :id="radioId(item.id)" />
          </div>
          <div class="text">
            <label :for="radioId(item.id)">
              <p>{{ maskName(item.name) }}</p>
              <p>{{ maskEmail(item.email) }}</p>
              <p>{{ maskTel(item.phone) }}</p>
              <p>{{ maskMobile(item.mobile) }}</p>
              <p>{{ item.cityName }}{{ item.townName }}{{ maskAddress(item.address) }}</p>
            </label>
          </div>
        </div>
      </div>
      <div class="btn-group">
        <a
          href="javascript:void(0);"
          class="btn btn-primary btn-round"
          @click="onConfirmSelect()"
          :data-dismiss="dataDismiss">
          確認選取
        </a>
      </div>
    </main>
  </div>
</template>

<script>
import { commitMutations, getState, renderState, commitMutation } from '@/helpers/vuex';
import { maskName, maskTel, maskMobile, maskAddress, maskEmail } from '@/helpers/mask';
import { telToObject } from '@/helpers/telephone';
import headerCart from '@/components/mobile/Header/header-cart.vue';

const components = {
  headerCart,
};

export default {
  name: 'address-modal',
  data() {
    return {
      /** v-model */
      picked: null,
    };
  },
  components,
  computed: {
    /** 從 API 下載的 [收件人記事本] */
    addressees: getState('AddressModalInfo', 'addressees'),
    /** 關閉 [收件人記事本] */
    dataDismiss: getState('AddressModalInfo', 'dataDismiss'),
    /** 判斷[收件人記事本]是否有資料 */
    hasInfo() {
      return renderState('AddressModalInfo', 'addressees').length > 0;
    },
    // 濾出畫面選擇的收件人並寫入到 store 及 dataDismiss 傳 'modal' 才能關閉頁面
    select() {
      const item = this.addressees.filter((item) => item.id === this.picked);
      commitMutations('AddressModalInfo', { selectedItem: item, dataDismiss: 'modal' });
      return item;
    },
  },
  methods: {
    maskName,
    maskTel,
    maskMobile,
    maskAddress,
    maskEmail,
    goBack() {
      commitMutation('DynamicComponents', 'deliveryMethod', 'step1-addressee');
    },
    radioId(id) {
      return `addressee-${id}`;
    },
    onConfirmSelect() {
      const selectItem = this.select[0];
      // 沒選擇就不能退出
      if (!selectItem) {
        alert('請選取一筆資料');
        return;
      }
      // 將選取的記事本單筆資料寫入收件人資訊
      commitMutations('RecipientInfo', {
        name: selectItem.name,
        email: selectItem.email,
        mobilePhone: selectItem.mobile,
        localPhoneArea: telToObject(selectItem.phone).area,
        localPhone: telToObject(selectItem.phone).number,
        localPhoneExt: telToObject(selectItem.phone).ext,
        addressCity: selectItem.cityName,
        addressCityId: selectItem.cityId,
        addressTown: selectItem.townName,
        addressTownId: selectItem.townId,
        address: selectItem.address,
        addressZip: selectItem.zipcode,
      });
      commitMutation('DynamicComponents', 'deliveryMethod', 'step1-addressee');
    },
  },
};
</script>

<style scoped>
h4.content {
  font-weight: normal;
  text-align: center;
  margin: 30px;
}
.radio-block {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
  width: 48px;
}
</style>
