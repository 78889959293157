<template>
  <div class="cart-check-bar fixed bottom step1main">
    <div class="btn-group">
      <button @click="goBackFromStep1" class="btn btn-primary-outline btn-round">上一步</button>
      <button @click="submit" class="btn btn-primary btn-round">確定</button>
    </div>
    <div class="inner-total">
      <div class="title">應付金額(含運)</div>
      <span class="label">合計</span>
      <span id="confirm_pay" class="price">
        {{ summary | dollar }}
      </span>
    </div>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { renderState, commitMutation } from '@/helpers/vuex';
import { goBackFromStep1 } from '@/helpers/navigation-goback-where';

const summary = () => {
  const amount = renderState('CartDetail', 'amount');
  const shipFare = renderState('CartDetail', 'shipFare');
  const isShipFare = renderState('CartDetail', 'isShipFare');
  const realShipFare = isShipFare ? shipFare : 0;
  const summary = amount + realShipFare;
  commitMutation('CartDetail', 'summary', summary);
  return summary;
};

const submit = function () {
  this.$emit('submit');
};

const filters = {
  dollar,
};

const methods = {
  submit,
  goBackFromStep1,
};
export default {
  name: 'step1-footer',
  filters,
  methods,
  computed: {
    summary,
  },
};
</script>
