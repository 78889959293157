<template>
  <div class="fieldset" :class="{ 'lock-form-box': isHasOrderName }">
    <label>
      <b class="notice">*</b>
      {{ prefix }}
    </label>
    <p v-if="isHasOrderName" class="has-type-input">
      {{ maskedName }}
    </p>
    <input
      v-else
      v-model="name"
      v-validate="validateRule"
      :name="errMsg"
      type="text"
      placeholder="請填寫中文全名"
      maxlength="7"
      autocomplete="off" />
    <p class="error">{{ errors.first(errMsg) }}</p>
  </div>
</template>

<script>
import { createComputed, renderState } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

// 有 memberName 即出現文字 ; 無則出現輸入框
const isHasOrderName = () => renderState('OrderInfo', 'isHasOrderName');

export default {
  name: 'order-name',
  inject: ['$validator'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
    errMsg: {
      type: String,
      default: '',
    },
    module: {
      type: String,
      default: '',
    },
  },
  computed: {
    isHasOrderName,
    name: createComputed('OrderInfo', 'name'),
    validateRule() {
      return 'required|chinese-only';
    },
  },
  setup(props) {
    const { maskedName } = useMask(props);
    return { maskedName };
  },
};
</script>
