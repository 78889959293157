<template>
  <div>
    <!-- 門市地址-->
    <div class="fieldset">
      <label><slot name="label">選擇門市</slot></label>
      <div class="select-group half">
        <!-- 縣市 -->
        <city-town-select
          v-model="cityIndex"
          :source="cities"
          name="city"
          v-validate="isNotPleaseSelect"></city-town-select>
        <!-- 鄉鎮 -->
        <city-town-select
          v-model="townIndex"
          :source="towns"
          name="town"
          v-validate="isNotPleaseSelect"></city-town-select>
      </div>
    </div>
    <div class="fieldset">
      <slot name="middle-label"></slot>
      <!--神腦門市-->
      <city-town-select
        v-model="storeIndex"
        :source="stores"
        name="store"
        v-validate="isNotPleaseSelect"></city-town-select>
    </div>
    <div class="fieldset">
      <p class="error" v-if="errors.has('city')">* {{ errors.first('city') }}</p>
      <p class="error" v-if="errors.has('town')">* {{ errors.first('town') }}</p>
      <p class="error" v-if="errors.has('store')">* {{ errors.first('store') }}</p>
    </div>
  </div>
</template>

<script>
import { renderState, getState, getGetter, commitMutation, commitMutations } from '@/helpers/vuex';
import cityTownSelect from '@/components/desktop/step1/common/city-town/city-town-select.vue';

/** 所有 API 資料 */
const allCityTownStores = getState('CityTownStores', 'allCityTownStores');

/** 綁定 city 的 index */
const cityIndex = {
  get() {
    return renderState('CityTownStores', 'cityIndex');
  },
  set(value) {
    this.townIndex = 0;
    commitMutation('CityTownStores', 'cityIndex', value);
    commitMutations(this.module, {
      senaoCity: this.cities[value].name,
      senaoTown: '',
    });
  },
};

/** 綁定 town 的 index */
const townIndex = {
  get() {
    return renderState('CityTownStores', 'townIndex');
  },
  set(value) {
    this.storeIndex = 0;
    commitMutation('CityTownStores', 'townIndex', value);
    commitMutation(this.module, 'senaoTown', this.towns[value].name);
  },
};

/** 綁定 store 的 index */
const storeIndex = {
  get() {
    return renderState('CityTownStores', 'storeIndex');
  },
  set(value) {
    commitMutation('CityTownStores', 'storeIndex', value);
  },
};

/** 鄉鎮市不能是 `請選擇` */
const isNotPleaseSelect = () => ({ is_not: 0 });

const props = {
  /** 與 Vuex 綁定的 module 名稱 "RecipientInfo" */
  module: {
    type: String,
    default: '',
  },
};

const components = {
  cityTownSelect,
};

export default {
  name: 'store-location-selector',
  inject: ['$validator'],
  components,
  props,
  computed: {
    allCityTownStores,
    cityIndex,
    townIndex,
    storeIndex,
    /** 顯示第一層 select 所需的 array */
    cities: getGetter('CityTownStores', 'cities'),
    /** 顯示第二層 select 所需的 array */
    towns: getGetter('CityTownStores', 'towns'),
    /** 顯示第三層 select 所需的 array */
    stores: getGetter('CityTownStores', 'stores'),
    isNotPleaseSelect,
  },
};
</script>
