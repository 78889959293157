<template>
  <div class="fieldset">
    <label>
      <slot name="label">
        <b class="notice">*</b>
        手機
        <span class="text-muted"></span>
      </slot>
    </label>
    <input
      v-if="isNotMasked"
      type="text"
      :name="errMsg"
      placeholder="請填寫手機號碼"
      maxlength="10"
      autocomplete="off"
      v-model="mobilePhone"
      v-validate="'required|mobile-phone'" />
    <input v-else v-model="maskedMobile" type="text" @focus="isNotMasked = true" />
    <p class="error" v-if="errors.has(errMsg)">
      <b class="notice">*</b>
      {{ errors.first(errMsg) }}
    </p>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

export default {
  name: 'recipient-mobilephone',
  inject: ['$validator'],
  props: ['errMsg', 'module'],
  computed: {
    /** [手機] */
    mobilePhone: createComputed('RecipientInfo', 'mobilePhone'),
  },
  created() {
    this.setIsNotMaskedByValue(this.mobilePhone);
  },
  setup(props) {
    const { maskedMobile, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedMobile, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
