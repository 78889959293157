<template>
  <div>
    <div class="store-icon">
      <img src="/images/icons/icon-location-red-32.svg" alt="特約中心" />
    </div>
    <div class="store-desc" v-for="(item, index) in info" :key="index">
      <h3 class="title">{{ item.storeName }}</h3>
      <p class="text-muted">
        營業時間：
        <a class="st_link" @click="isShowAccordionContent = !isShowAccordionContent">
          <span :class="storeOpenOrClosedStyle">{{ storeOpenOrClosedText }}</span>
          ． {{ item.weekList[calculateWeekListMatchingDay].day }}
          {{ item.weekList[calculateWeekListMatchingDay].time }}
          <i :class="['icon', isShowAccordionContent ? 'icon-arrow-up' : 'icon-arrow-down']"></i>
        </a>
      </p>
      <ul id="st_list" v-if="isShowAccordionContent">
        <li v-for="(item, index) in item.weekList" :key="index">
          <p :class="{ 'font-bold': index === calculateWeekListMatchingDay }">{{ item.day }}</p>
          <p :class="{ 'font-bold': index === calculateWeekListMatchingDay }">{{ item.time }}</p>
        </li>
      </ul>
      <p class="text-muted">
        聯絡電話：{{ item.tel }}
        <br />
        門市地址：{{ item.fullAddress }}
      </p>
    </div>
  </div>
</template>
<script>
import { calculateWeekListMatchingDay } from '@/helpers/date';

const computed = {
  calculateWeekListMatchingDay,
  storeOpenOrClosedText() {
    return this.info.length && this.info[0].weekList[this.calculateWeekListMatchingDay].time === '休息'
      ? '休息中'
      : '營業中';
  },
  storeOpenOrClosedStyle() {
    return this.info.length && this.info[0].weekList[this.calculateWeekListMatchingDay].time === '休息'
      ? 'st_red'
      : 'st_green';
  },
};

export default {
  // 地圖頁與 store-form 頁共用此組件
  name: 'store-hours',
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShowAccordionContent: false,
    };
  },
  computed,
};
</script>
<style scoped>
.icon-arrow-up {
  background-color: transparent;
  background-image: url('/images/icons/icon-arrow-up.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}
</style>
