<template>
  <div class="panel">
    <div class="panel-head">
      <h3>訂購人資訊</h3>
    </div>
    <div class="panel-body">
      <form class="row">
        <p v-if="!isLoginUser" class="divider notice">訂單成立後將以您的手機作為帳號註冊成為神腦會員</p>
        <order-name :module="module" :prefix="'訂購人'" :err-msg="'訂購人姓名'"></order-name>
        <order-mobile :module="module" :prefix="'手機'" :err-msg="'訂購人電話'"></order-mobile>
        <p v-if="isLoginUser" class="divider text-muted">此為神腦會員帳號，無法異動</p>
        <order-mail :module="module" :prefix="'Email'" :err-msg="'訂購人信箱'"></order-mail>
        <order-address :module="module" :states="orderAddressStates"></order-address>
        <p v-if="isLoginUser" class="divider text-muted">
          * 欲修改中文姓名請來電至客服專線 02-8978-6068，其他資料修改請至會員中心 > 會員資料
        </p>
      </form>
    </div>
    <go-login-modal />
  </div>
</template>

<script>
import { isLoginUser } from '@/helpers/is-from-app';
import orderName from '@/components/mobile/step1/order-info/child/order-name.vue';
import orderMobile from '@/components/mobile/step1/order-info/child/order-mobile.vue';
import orderMail from '@/components/mobile/step1/order-info/child/order-mail.vue';
import orderAddress from '@/components/mobile/step1/order-info/child/order-address.vue';
import GoLoginModal from '@/components/common/modals/go-login-modal.vue';

export default {
  name: 'order-info',
  inject: ['$validator'],
  components: {
    orderName,
    orderMobile,
    orderMail,
    orderAddress,
    GoLoginModal,
  },
  data: () => ({
    module: 'OrderInfo',
    orderAddressStates: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
  }),
  computed: {
    isLoginUser,
  },
};
</script>
