<template>
  <div class="container">
    <!-- header -->
    <header-cart>
      <template slot="header-middle">填寫配送資料</template>
      <template slot="header-right">
        <div></div>
      </template>
    </header-cart>
    <!-- content -->
    <main class="offset bottom gray" style="min-height: 100vh">
      <div>
        <!-- 商品總計-->
        <summary-bar></summary-bar>
        <!-- 步驟說明-->
        <step-icon-bar :step="1"></step-icon-bar>
      </div>
      <div>
        <!-- 訂購人資訊 -->
        <order-info></order-info>
      </div>
      <div>
        <!-- 收件人資訊 -->
        <recipient-info></recipient-info>
      </div>
      <!-- footer bar -->
      <step1footer @submit="submit"></step1footer>
    </main>
  </div>
</template>

<script>
import headerCart from '@/components/mobile/Header/header-cart.vue';
import orderInfo from '@/components/mobile/step1/order-info/order-info.vue';
import recipientInfo from '@/components/mobile/step1/recipient-info/recipient-info.vue';
import step1footer from '@/components/mobile/step1/step1-footer.vue';
import stepIconBar from '@/components/mobile/step-icon-bar.vue';
import summaryBar from '@/components/mobile/step1/step1-summary-bar.vue';

const components = {
  headerCart,
  step1footer,
  stepIconBar,
  summaryBar,
  orderInfo,
  recipientInfo,
};

const methods = {
  submit() {
    if (this.errors.items.length > 0) {
      // 若畫面有錯誤未清除，按下確定則跳出提示。
      alert(this.errors.items[0].msg);
      return;
    }
    this.$emit('submit');
  },
};

export default {
  name: 'step1-addressee',
  inject: ['$validator'],
  components,
  methods,
};
</script>
