<template>
  <div class="fieldset">
    <label>
      <slot name="label">
        市話
        <span class="text-muted">(範例: 02-12340000 #5678)</span>
      </slot>
    </label>
    <div class="input-telephone">
      <input
        v-model="localPhoneArea"
        v-validate="'local-phone-area'"
        :name="phoneAreaName"
        :disabled="isRtype"
        class="input-sm pristine untouched"
        maxlength="4"
        autocomplete="off"
        placeholder="區號"
        type="text" />
      <input
        v-if="isNotMasked"
        v-model="localPhone"
        v-validate="'local-phone'"
        :name="phoneName"
        :disabled="isRtype"
        class="input-lg pristine untouched"
        maxlength="8"
        autocomplete="off"
        placeholder="電話號碼"
        type="text" />
      <input v-else v-model="maskedTel" class="input-lg pristine untouched" type="text" @focus="isNotMasked = true" />
      <input
        v-model="localPhoneExt"
        v-validate="'local-phone-ext'"
        :name="phoneExtName"
        :disabled="isRtype"
        class="input-md pristine untouched"
        maxlength="6"
        autocomplete="off"
        placeholder="分機"
        type="text" />
    </div>
    <p v-if="errors.has(phoneAreaName)" class="error">
      {{ errors.first(phoneAreaName) }}
    </p>
    <p v-if="errors.has(phoneName)" class="error">
      {{ errors.first(phoneName) }}
    </p>
    <p v-if="errors.has(phoneExtName)" class="error">
      {{ errors.first(phoneExtName) }}
    </p>
  </div>
</template>

<script>
import { createAdvComputed } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

const index = {
  localPhoneArea: 0,
  localPhone: 1,
  localPhoneExt: 2,
};

export default {
  name: 'recipient-localphone',
  inject: ['$validator'],
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return ['localPhoneArea', 'localPhone', 'localPhoneExt'];
      },
    },
    phoneAreaName: {
      type: String,
      default: 'phoneAreaName',
    },
    phoneName: {
      type: String,
      default: 'phoneName',
    },
    phoneExtName: {
      type: String,
      default: 'phoneExtName',
    },
    isRtype: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /** [市話區碼] */
    localPhoneArea: createAdvComputed(index.localPhoneArea),
    /** [市話] */
    localPhone: createAdvComputed(index.localPhone),
    /** [市話分機] */
    localPhoneExt: createAdvComputed(index.localPhoneExt),
  },
  created() {
    this.setIsNotMaskedByValue(this.localPhone);
  },
  setup(props) {
    const { maskedTel, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedTel, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
