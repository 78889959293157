<template>
  <div class="fieldset" :class="{ 'lock-form-box': isHasOrderMobile }">
    <label>
      <b class="notice">*</b>
      {{ prefix }}
    </label>
    <p v-if="isHasOrderMobile" class="has-type-input">
      {{ maskedMobile }}
    </p>
    <input
      v-else
      type="text"
      v-model="mobilePhone"
      v-validate="'required|mobile-phone'"
      :name="errMsg"
      placeholder="請填寫手機號碼"
      maxlength="10"
      @blur="validatePhone"
      autocomplete="off" />
    <p class="error">{{ errors.first(errMsg) }}</p>
  </div>
</template>

<script>
import { createComputed, renderState } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import { checkMembership } from '@/helpers/login-check';
import { useMask } from '@/composables/mask';

const isHasOrderMobile = () => renderState('OrderInfo', 'isHasOrderMobile');

export default {
  name: 'order-mobile',
  inject: ['$validator'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
    errMsg: {
      type: String,
      default: '',
    },
    module: {
      type: String,
      default: '',
    },
  },
  computed: {
    isHasOrderMobile,
    mobilePhone: createComputed('OrderInfo', 'mobilePhone'),
    isLoginUser,
  },
  methods: {
    validatePhone() {
      !this.isLoginUser &&
        this.$validator.validate('訂購人電話', this.mobilePhone).then((isValid) => isValid && checkMembership());
    },
  },
  setup(props) {
    const { maskedMobile } = useMask(props);
    return { maskedMobile };
  },
};
</script>
