<template>
  <div class="fieldset" :class="{ 'lock-form-box': isHasOrderAddress }">
    <label>
      <b class="notice">*</b>
      聯絡地址
    </label>
    <city-town-taiwan
      :module="module"
      :states="states"
      :island="true"
      :addressName="'地址'"
      :cityName="'縣市'"
      :townName="'鄉鎮區'"
      :isOrderAddress="isHasOrderAddress"></city-town-taiwan>
    <p class="error hide">* 請填寫完整地址</p>
  </div>
</template>

<script>
import CityTownTaiwan from '@/components/mobile/step1/form-item/common-city-town-taiwan.vue';
import { renderState } from '@/helpers/vuex';

/* 有 memberAddress 即出現文字 ; 無則出現輸入框 ; 亦表示只有在訂購人區塊才會出現原先的訂購人資料，不影響收件人地址 */
const isHasOrderAddress = () => renderState('OrderInfo', 'isHasAddress') && renderState('OrderInfo', 'isHasZipCode');

export default {
  name: 'order-address',
  components: {
    CityTownTaiwan,
  },
  props: ['module', 'states', 'isOrderAddress'],
  computed: {
    isHasOrderAddress,
  },
};
</script>
