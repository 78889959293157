<template>
  <div class="panel">
    <div class="panel-head"><h3>收件人資訊</h3></div>
    <div class="panel-body">
      <!-- 員工編號 for R 身份 -->
      <recipient-employee :module="module" :states="recipientEmployeeIdStates" v-if="isEmployee"></recipient-employee>
      <!-- 收件人 -->
      <recipient-name
        :module="module"
        :states="recipientNameStates"
        :prefix="'收件人'"
        :postfix="'(中文全名)'"
        :err-msg="'recipientName'"></recipient-name>
      <recipient-mobilephone
        :module="module"
        :states="recipientMobilephoneStates"
        :err-msg="'手機號碼'"></recipient-mobilephone>
      <recipient-localphone
        :module="module"
        :states="recipientLocalphoneStates"
        :phoneAreaName="'recipientLocalPhoneArea'"
        :phoneName="'recipientLocalPhone'"
        :phoneExtName="'recipientLocalPhoneExt'"></recipient-localphone>
      <recipient-email :module="module" :states="recipientEmailStates"></recipient-email>
      <recipient-address></recipient-address>
    </div>
  </div>
</template>

<script>
import { getState } from '@/helpers/vuex';
import RecipientAddress from '@/components/mobile/step1/recipient-info/child/recipient-address.vue';
import RecipientEmail from '@/components/mobile/step1/recipient-info/child/recipient-email.vue';
import RecipientEmployee from '@/components/mobile/step1/recipient-info/child/recipient-employee-id.vue';
import RecipientLocalphone from '@/components/mobile/step1/recipient-info/child/recipient-localphone.vue';
import RecipientMobilephone from '@/components/mobile/step1/recipient-info/child/recipient-mobile.vue';
import RecipientName from '@/components/mobile/step1/recipient-info/child/recipient-name.vue';

export default {
  name: 'recipient-info',
  inject: ['$validator'],
  components: {
    RecipientEmployee,
    RecipientName,
    RecipientMobilephone,
    RecipientLocalphone,
    RecipientEmail,
    RecipientAddress,
  },
  data: () => ({
    /** module 名稱 */
    module: 'RecipientInfo',
    /** EmployeeId 所使用的 states */
    recipientEmployeeIdStates: ['employeeId'],
    /** <recipient-name/> 所使用的 states */
    recipientNameStates: ['name'],
    /** <recipient-mobilephone/> 所使用的 states */
    recipientMobilephoneStates: ['mobilePhone'],
    /** <recipient-localphone/> 所使用的 states */
    recipientLocalphoneStates: ['localPhoneArea', 'localPhone', 'localPhoneExt'],
    /** <recipient-email/> 所使用的 states */
    recipientEmailStates: ['email'],
  }),
  computed: {
    /** 從 API 取得的 [是否顯示員工編號] */
    isEmployee: getState('RecipientInfo', 'isEmployee'),
  },
};
</script>
