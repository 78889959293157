<template>
  <div :class="divWrapper()">
    <slot name="header-link">
      <label>
        門市資訊
        <a class="pull-right" href="javascript:void(0);" @click="change()"> 更改 </a>
      </label>
    </slot>
    <div :class="insideWrapper()">
      <store-hours :info="storeInfo" />
    </div>
  </div>
</template>

<script>
import { commitMutation, renderGetter, renderState } from '@/helpers/vuex';
import storeHours from '@/components/mobile/step1/store-hours.vue';

const props = {
  atMapPage: Boolean,
};

const methods = {
  change() {
    commitMutation('DynamicComponents', 'deliveryMethod', 'store-select');
  },
  divWrapper() {
    /** 判斷是否於地圖頁 */
    return this.atMapPage ? 'store-info' : 'fieldset';
  },
  insideWrapper() {
    return {
      'store-info': !this.atMapPage,
    };
  },
};

const computed = {
  storeInfo() {
    // 取得上一次在 store-select 頁按確認的門市資料
    const userSelectStore = renderState('RecipientInfo', 'selectStore');
    const getStoreName = renderGetter('RecipientInfo', 'getterStoreName');
    const hasSelectStore = getStoreName !== '請選擇門市';
    return hasSelectStore ? userSelectStore : [];
  },
};

export default {
  // 地圖頁與 store-form 頁共用此組件
  name: 'store-info',
  components: {
    storeHours,
  },
  methods,
  computed,
  props,
};
</script>
