<template>
  <div class="fieldset">
    <label>
      <b class="notice">*</b>
      取貨門市
    </label>
    <input
      class="btn-block has-input-type"
      :value="storeName"
      @input="$emit('input', $event.target.value)"
      v-validate="'required'"
      :name="errMsg"
      type="text"
      placeholder="storeName"
      autocomplete="off"
      @click="showSelect()" />
    <p class="error" v-if="errors.has(errMsg)">* {{ errors.first(errMsg) }}</p>
  </div>
</template>

<script>
import { commitMutation } from '@/helpers/vuex';

const methods = {
  // 轉頁去選擇門市
  showSelect() {
    commitMutation('DynamicComponents', 'deliveryMethod', 'store-select');
  },
};

export default {
  name: 'store-name',
  inject: ['$validator'],
  props: ['errMsg', 'storeName'],
  methods,
};
</script>
<style scoped>
.btn-block.has-input-type {
  cursor: pointer;
}
</style>
