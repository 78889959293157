<template>
  <div class="fieldset">
    <label>
      <slot name="label">
        <b class="notice">*</b>
        收件地址
        <span class="text-muted"></span>
      </slot>
    </label>
    <city-town-taiwan
      :module="moduleName"
      :states="stateNames"
      :island="false"
      :addressName="'地址'"
      :cityName="'縣市'"
      :townName="'鄉鎮區'"></city-town-taiwan>
    <p class="error">(暫無提供外島配送服務，且不接受郵政信箱)</p>
    <label v-if="isLoginUser" for="recipient-addNote">
      <input type="checkbox" class="checkbox" id="recipient-addNote" v-model="isSave" />
      加入收件人記事本
    </label>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import CityTownTaiwan from '@/components/mobile/step1/form-item/common-city-town-taiwan.vue';

export default {
  name: 'recipient-address',
  inject: ['$validator'],
  components: {
    CityTownTaiwan,
  },
  data: () => ({
    /** state 的 module 名稱 */
    moduleName: 'RecipientInfo',
    /** 所綁定的 state */
    stateNames: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
  }),
  computed: {
    isSave: createComputed('RecipientInfo', 'isSave'),
    isLoginUser,
  },
};
</script>
