<template>
  <div class="panel">
    <div class="panel-head">
      <h3>門市取貨資訊</h3>
    </div>
    <div class="panel-body">
      <!-- 收件人 -->
      <recipient-name
        :module="module"
        :prefix="'收件人'"
        :postfix="'(中文全名)'"
        :err-msg="'recipientName'"></recipient-name>

      <!-- 手機號碼 -->
      <recipient-mobilephone :module="module" :err-msg="'手機號碼'"></recipient-mobilephone>

      <!-- 市話 -->
      <recipient-localphone
        :module="module"
        :states="recipientLocalphoneStates"
        :phoneAreaName="'recipientLocalPhoneArea'"
        :phoneName="'recipientLocalPhone'"
        :phoneExtName="'recipientLocalPhoneExt'"></recipient-localphone>

      <!-- 選取門市 -->
      <store-name :store-name="getStoreName" :err-msg="'門市'" name="selectStore"></store-name>

      <!-- 門市資訊 -->
      <store-info v-if="hasStoreInfo"></store-info>
    </div>
  </div>
</template>

<script>
import { getGetter } from '@/helpers/vuex';
import validator from '@/validators/mobile-step1';
import RecipientLocalphone from '@/components/mobile/step1/recipient-info/child/recipient-localphone.vue';
import RecipientMobilephone from '@/components/mobile/step1/recipient-info/child/recipient-mobile.vue';
import RecipientName from '@/components/mobile/step1/recipient-info/child/recipient-name.vue';
import storeInfo from '@/components/mobile/step1/form-item/store-info.vue';
import storeName from '@/components/mobile/step1/form-item/store-name.vue';

const hasStoreInfo = function () {
  return this.getStoreName !== '請選擇門市';
};

const components = {
  RecipientName,
  RecipientMobilephone,
  RecipientLocalphone,
  storeName,
  storeInfo,
};

validator();

export default {
  name: 'store-form',
  inject: ['$validator'],
  components,
  data: () => ({
    /** module 名稱 */
    module: 'RecipientInfo',
    /** <recipient-localphone/> 所使用的 states */
    recipientLocalphoneStates: ['localPhoneArea', 'localPhone', 'localPhoneExt'],
  }),
  computed: {
    getStoreName: getGetter('RecipientInfo', 'getterStoreName'),
    hasStoreInfo,
  },
};
</script>
