<template>
  <component :is="dynamicComponent" :key="dynamicComponent" :module="module" @submit="onConfirmSubmit"></component>
</template>

<script>
import { saveReceipt } from '@/api/step1/set-receipt';
import { getCityIdByName, getTownIdByName } from '@/helpers/city-town';
import { onShippingComplete, checkDelivery } from '@/helpers/tracking/ga';
import { getState, renderState, getAction, commitMutation } from '@/helpers/vuex';
import { openModal } from '@/helpers/modal';
import fullBackground from '@/mixins/full-background';
import validator from '@/validators/mobile-step1';
import addressModal from '@/components/mobile/step1/recipient-info/child/address-modal.vue'; // 收件人記事本 modal
import step1Addressee from '@/components/mobile/step1/step1-addressee.vue'; // 宅配 step1Addressee component
import step1Store from '@/components/mobile/step1/step1-store.vue'; // 門市取貨 step1Store component
import storeGoogleMap from '@/components/mobile/step1/store-googleMap.vue'; // 地圖選取門市 storeGoogleMap component
import storeSelect from '@/components/mobile/step1/store-select.vue'; // 門市取貨選取頁 storeSelect component

validator();

const data = () => ({
  /** module 名稱 - 收件人資訊與取貨資訊 */
  module: 'RecipientInfo',
  step1SubmitBtnDisabled: false,
});

// 按下 submit 呼叫 validator() 驗證
const onConfirmSubmit = function () {
  this.$validator.validateAll().then((result) => {
    this.checkOutValidate(result);
  });
};

// 送出訂單前的驗證
const checkOutValidate = function (result) {
  // vee-validate has error case
  if (!result) {
    const { msg } = this.errors.items[0];
    this.step1SubmitBtnDisabled = false;
    if (msg) {
      alert(msg);
      return false;
    }
    return false;
  }
  const isDeliveryIsStore = renderState('DeliveryInfo', 'delivery') === '2';
  const notSelectStore = renderState('RecipientInfo', 'senaoStoreId') === '';
  // 選取門市運送且無選取門市情境下要跳警告視窗
  if (isDeliveryIsStore && notSelectStore) {
    this.step1SubmitBtnDisabled = false;
    alert('請選擇門市');
    return false;
  }
  const recipientInfoAddress = this.$store.state.RecipientInfo.address;
  // 選擇宅配才要檢查收件人地址
  if (!isDeliveryIsStore && recipientInfoAddress === '') {
    alert('請填寫真實地址');
    return false;
  }
  if (this.isUserMember) {
    return openModal('GoLoginModal');
  }
  return this.submitProcess(isDeliveryIsStore);
};

const submitProcess = function (isDeliveryIsStore) {
  // 為防止連擊行為
  if (this.step1SubmitBtnDisabled) {
    return;
  }
  this.step1SubmitBtnDisabled = true;
  const cityName = isDeliveryIsStore
    ? renderState('RecipientInfo', 'senaoCity')
    : renderState('RecipientInfo', 'addressCity');
  const townName = isDeliveryIsStore
    ? renderState('RecipientInfo', 'senaoTown')
    : renderState('RecipientInfo', 'addressTown');
  const receiptCityId = getCityIdByName({ cityName });
  const receiptTownId = getTownIdByName({ cityName, townName });
  const params = {
    isAddReceipt: renderState('RecipientInfo', 'isSave') === true ? 1 : 0,
    receiptAddress: isDeliveryIsStore
      ? renderState('RecipientInfo', 'senaoStoreAddress').replace(`${cityName}${townName}`, '')
      : renderState('RecipientInfo', 'address'),
    receiptCity: cityName,
    receiptDistrict: townName,
    receiptZipCode: isDeliveryIsStore
      ? renderState('RecipientInfo', 'senaoStoreZipcode')
      : renderState('RecipientInfo', 'addressZip'),
    receiptMobile: renderState('RecipientInfo', 'mobilePhone'),
    receiptEmployee: renderState('RecipientInfo', 'employeeId'),
    receiptName: renderState('RecipientInfo', 'name'),
    receiptEmail: renderState('RecipientInfo', 'email'),
    receiptStoreId: isDeliveryIsStore ? renderState('RecipientInfo', 'senaoStoreId') : '',
    receiptStoreName: isDeliveryIsStore ? renderState('RecipientInfo', 'senaoStore') : '',
    receiptPhoneNo: renderState('RecipientInfo', 'localPhone'),
    receiptPhoneCode: renderState('RecipientInfo', 'localPhoneArea'),
    receiptPhoneExt: renderState('RecipientInfo', 'localPhoneExt'),
    receiptCityId,
    receiptTownId,
  };
  const formData = Object.entries(params).reduce((data, [key, value]) => {
    data.append(key, value);
    return data;
  }, new FormData());
  saveReceipt(formData)
    .then((res) => this.response(res))
    .then(this.saveToTrackingCode)
    .catch(this.catchResponse);
};
// 例外情況:導去 cartChoice 頁
const catchResponse = function () {
  this.step1SubmitBtnDisabled = false;
  commitMutation('DynamicComponents', 'deliveryMethod', '');
  return this.$router.push('/m/cart/choice');
};
// 判斷各情境
const response = (res) => {
  // API 回傳失敗，跳出 alert
  if (res.data.code !== 1) {
    alert(res.data.description);
    catchResponse();
    return false;
  }
  return res;
};
const saveToTrackingCode = function () {
  const deliveryType = renderState('DeliveryInfo', 'delivery');
  commitMutation('DynamicComponents', 'deliveryMethod', '');
  commitMutation('RecipientInfo', 'isSaveReceipt', true);
  // for GA
  onShippingComplete(2, checkDelivery(deliveryType));
  this.$router.push('/m/cart/step2');
};

const methods = {
  onConfirmSubmit,
  checkOutValidate,
  submitProcess,
  response,
  saveToTrackingCode,
  catchResponse,
};

const components = {
  addressModal,
  step1Addressee,
  step1Store,
  storeSelect,
  storeGoogleMap,
};

const mounted = function () {
  const deliveryType = renderState('DeliveryInfo', 'delivery');
  // 如無透過 cartMain 進入則 store 裡的選擇配送方式則無資料，需導去 cartChoice
  if (!deliveryType) {
    this.$router.push('/m/cart/choice');
    return;
  }
  // 如 user 使用瀏覽器按上一頁從 step2 回 step1，會出現空白頁，故需判斷指定
  commitMutation('DynamicComponents', 'deliveryMethod', deliveryType === '1' ? 'step1-addressee' : 'step1-store');
  // 呼叫 [getShipFare] API 比對金額
  getAction('CartDetail', 'getShipFromStep1')();
  getAction('CityTownTaiwan', 'getAllCityTownTaiwan')();
};
export default {
  name: 'step1',
  components,
  data,
  methods,
  mounted,
  computed: {
    /** 依照 user 選的配送方式 render 出對應的 component */
    dynamicComponent: getState('DynamicComponents', 'deliveryMethod'),
    isUserMember: getState('RegisterSenaoMembership', 'isUserMember'),
  },
  mixins: [fullBackground],
};
</script>
