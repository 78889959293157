import axios from 'axios';

const API_PATH = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_SET_RECEIPT}`;

/** 購物車異動相關動作 */
const cartChangePost = (url, data) => {
  // xsrf token flag
  data.append('enabledXsrf', true);

  // axios config
  const config = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url,
    data,
  };
  return axios(config);
};
/** 收件人記事本 */
export const saveReceipt = (formData) => cartChangePost(API_PATH, formData);
