<template>
  <div class="fieldset">
    <label>銷售員工工號</label>
    <input v-model="id" maxlength="10" autocomplete="off" type="text" />
  </div>
</template>

<script>
import { compose, prop } from 'ramda';
import { createAdvComputed } from '@/helpers/vuex';

const index = {
  name: 0,
};
const id = compose(createAdvComputed, prop('name'))(index);

export default {
  name: 'recipient-employee-id',
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    id,
  },
};
</script>
