<template>
  <div class="fieldset">
    <label>
      <slot name="label">
        <b class="notice">*</b>
        Email
      </slot>
    </label>
    <input
      v-if="isNotMasked"
      type="text"
      :name="emailName"
      placeholder="請填寫E-Mail"
      maxlength="40"
      autocomplete="off"
      v-model="name"
      v-validate="'required|custom-email'" />
    <input v-else v-model="maskedEmail" type="text" @focus="isNotMasked = true" />
    <p v-if="errors.has(emailName)" class="error">* {{ errors.first(emailName) }}</p>
  </div>
</template>

<script>
import { compose, prop } from 'ramda';
import { createAdvComputed } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

const index = {
  name: 0,
};

const name = compose(createAdvComputed, prop('name'))(index);

export default {
  name: 'recipient-email',
  inject: ['$validator'],
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return [];
      },
    },
    emailName: {
      type: String,
      default: 'email',
    },
  },
  computed: {
    name,
  },
  created() {
    this.setIsNotMaskedByValue(this.emailName);
  },
  setup(props) {
    const { maskedEmail, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedEmail, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
