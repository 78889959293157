<template>
  <div class="total shrink sticky">
    <div class="title">
      共
      <b class="spacing">{{ getCartDetailCountFilterGift }}</b>
      項商品
    </div>
    <div class="total-item">
      <span class="label">未含運 應付總金額</span>
      <span class="price">{{ amount }}</span>
    </div>
    <div class="total-item">
      <span class="label">運費</span>
      <span class="price">{{ shipping | dollar }}</span>
    </div>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { renderState } from '@/helpers/vuex';

// 取得商品數量, 贈品除外 */
const getCartDetailCountFilterGift = () => renderState('CartDetail', 'cartCount');

// 取得總計
const amount = function () {
  const amount = renderState('CartDetail', 'amount');
  return `$${amount.toLocaleString()}`;
};

// 取得運費
const shipping = function () {
  const isShipFare = renderState('CartDetail', 'isShipFare');
  const shipFare = renderState('CartDetail', 'shipFare');
  if (isShipFare === 0) {
    return 0;
  }
  return shipFare;
};

const filters = {
  dollar,
};

export default {
  name: 'step1-summary-bar',
  filters,
  computed: {
    getCartDetailCountFilterGift,
    amount,
    shipping,
  },
};
</script>
